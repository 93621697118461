<script setup>
	const { items, bullets, arrowPrefix, bulletStyle, threshold } = defineProps({
		items: {
			type: [Object, Array],
			required: true,
		},
		bullets: {
			type: Boolean,
			required: false,
			default: true,
		},
		arrowPrefix: {
			type: String,
			required: false,
			default: 'chevron',
		},
		bulletStyle: {
			type: String,
			required: false,
			default: 'primary',
		},
		threshold: {
			type: [String, Number],
			required: false,
			default: 0.25,
		},
	});
	const emit = defineEmits(['updateActive']);
</script>

<template>
	<MessCarousel
		:items="items"
		:bullets="bullets"
		:bulletStyle="bulletStyle"
		:arrowPrefix="arrowPrefix"
		:threshold="threshold"
		class="project-carousel"
		@updateActive="(index) => emit('updateActive', index)"
	>
		<template #item="data">
			<slot name="item" v-bind="data" />
		</template>
	</MessCarousel>
</template>

<style lang="scss">
	.mess-carousel-draggable {
		.scroll-container {
			display: flex;
			flex-direction: row;
			align-items: center;

			.arrow {
				svg {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}
		}
	}
</style>
